export const TRANSACTION_TYPES = {

    CREATE : 'C',
    EDIT: 'E',
    SHOW: 'S',

};

export const ROLE_IDS = {
    1: 'ADMIN',
    2: 'SUPERADMIN',
    3: 'MEMBER',
    4: 'GUEST',
};

export const RISK_ZONES_STYLES = {

    RANGE: [
        { value: 'Aceptable', style: 'bg-green-100 text-green-800' },
        { value: 'Tolerable', style: 'bg-yellow-100 text-yellow-800' },
        { value: 'Inaceptable', style: 'bg-orange-100 text-orange-800' },
        { value: 'Inadmisible', style: 'bg-red-100 text-red-800' },
    ]

};
