import { TRANSACTION_TYPES } from '../utils/constants';

const companiesRoutes = [

    {
        path: '/companies',
        name: 'CompaniesPage',
        component: () => import('../views/companies/CompaniesComponent.vue'),
        meta: { requiresAuth: true }

    },
    {
        path: '/companies/create',
        name: 'CompaniesCreatePage',
        component: () => import('../views/companies/CompaniesManagerComponent.vue'),
        props: { type: TRANSACTION_TYPES.CREATE },
        meta: { requiresAuth: true }
    },
    {
        path: '/companies/edit/:companyId',
        name: 'CompaniesEditPage',
        component: () => import('../views/companies/CompaniesManagerComponent.vue'),
        props: route => ({ type: TRANSACTION_TYPES.EDIT, companyId: route.params.companyId }),
        meta: { requiresAuth: true }
    },
    {
        path: '/companies/show/:companyId',
        name: 'CompaniesShowPage',
        component: () => import('../views/companies/CompaniesManagerComponent.vue'),
        props: route => ({ type: TRANSACTION_TYPES.SHOW, companyId: route.params.companyId }),
        meta: { requiresAuth: true }
    },
];

export default companiesRoutes;