import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAuthStore = defineStore('auth', () => {

    const id = ref();
    const accessToken = ref(null);
    const subdomain = ref('app');
    const language = ref(null);
    const roles = ref([]);
    const email = ref(null);
    const name = ref(null);
    const lastName = ref(null);
    const baseUrl = ref(process.env.VUE_APP_BASE_URL);
    const scope = ref({});

    function setAuthData(userId, token, newSubdomain, newLanguage, roles, newName, newLastName, newEmail) {

        id.value = userId;
        accessToken.value = token;
        subdomain.value = newSubdomain;
        language.value = newLanguage;
        roles.value = roles;
        name.value = newName;
        lastName.value = newLastName;
        email.value = newEmail;

    }

    function clearAuthData() {

        id.value = null;
        accessToken.value = null;
        subdomain.value = '';
        language.value = null;
        roles.value = [];
        name.value = null;
        lastName.value = null;
        email.value = null;
        scope.value = {};

    }

    function setScope(newScope){

        scope.value = newScope;

    }

    function setEmail(newEmail) {

        email.value = newEmail;

    }

    return {

        id,
        accessToken,
        subdomain,
        language,
        roles,
        baseUrl,
        email,
        name,
        scope,
        lastName,
        setAuthData,
        clearAuthData,
        setEmail,
        setScope,
    };

}, {

    persist: {

        storage: sessionStorage,

    },

});
