export default {

    vulnerabilities: 'Vulnerabilities',
    vulnerability: 'Vulnerability',
    vulnerabilitiesOfAsset: 'Vulnerabilities of asset',
    newVulnerability: 'New Vulnerability',
    getVulnerabilitysError: 'An error occurred while fetching vulnerabilities',
    deleteVulnerabilityError: 'An error occurred while deleting the vulnerability',
    deleteVulnerabilitySuccess: 'Vulnerability successfully deleted',
    severity: 'Severity',
    noSeverity: 'Severity not found',
    noSeverities: 'Severities not found',
    editVulnerabilityError: 'An error occurred while editing the vulnerability',
    createVulnerabilityError: 'An error occurred while creating the vulnerability',
    editVulnerabilitySuccess: 'Vulnerability successfully updated',
    createVulnerabilitySuccess: 'Vulnerability successfully created',
    createVulnerability: 'Create Vulnerability',
    createLoaderVulnerability: 'Creating Vulnerability',
    editLoaderVulnerability: 'Editing Vulnerability',
    deleteLoaderVulnerability: 'Deleting Vulnerability',
    editVulnerability: 'Edit Vulnerability',

};
