export default {
    risksMenu: 'Risk calculation',
    riskManagement: 'Risk Management',
    risks: 'Risks',
    risksOfThreat: 'Risks of the threat',
    probability: 'Probability',
    showAssetsRisk: 'Show risky assets',
    threatCategory: 'Category/Subcategory',
    threatAsset: 'Threat/Asset',
    selectMeasure: 'Select a measure',
    selectProbability: 'Select a probability',
    noFactorsMessage: 'you have not yet selected the impact factors that apply to this asset',
    noThreatsMessage: 'this asset has no associated threats yet. To carry out the risk calculation, it is necessary to identify the possible threats that could affect this asset.',
    riskLevel: 'Risk Level',
    saveRisk: 'Save Risk',
    noRiskZone: 'No risk on this zone',
    noRisksMessage: 'You still do not have calculated risk, if you want to calculate the risk of your assets, you can do so in the risk calculation section.',

};
