//import { TRANSACTION_TYPES } from '../utils/constants';

const probabilityMeasureRoutes = [
    {
        path: '/probability-measure',
        name: 'ProbabilityMeasurePage',
        component: () => import('../views/probabilityMeasureTypes/ProbabilityMeasureTypesComponent.vue'),
    },
    // {
    //     path: '/vulnerabilities/create',
    //     name: 'VulnerabilityCreatePage',
    //     component: () => import('../views/vulnerabilities/VulnerabilitiesManagerComponent.vue'),
    //     props: { type: TRANSACTION_TYPES.CREATE }
    // },
    // {
    //     path: '/vulnerabilities/edit/:vulnerabilityId',
    //     name: 'VulnerabilitiesEditPage',
    //     component: () => import('../views/vulnerabilities/VulnerabilitiesManagerComponent.vue'),
    //     props: route => ({ type: TRANSACTION_TYPES.EDIT, vulnerabilityId: route.params.vulnerabilityId })
    // },
    // {
    //     path: '/vulnerabilities/show/:vulnerabilityId',
    //     name: 'VulnerabilitiesShowPage',
    //     component: () => import('../views/vulnerabilities/VulnerabilitiesManagerComponent.vue'),
    //     props: route => ({ type: TRANSACTION_TYPES.SHOW, vulnerabilityId: route.params.vulnerabilityId })
    // },
];

export default probabilityMeasureRoutes;