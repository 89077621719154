export default {

    threat: 'Amenaza',
    threats: 'Amenazas',
    newThreat: 'Agregar nueva amenaza',
    actor: 'Actor',
    actorPlaceHolder: 'Selecciona un efecto',
    noActor: 'Actor no encontrado',
    noActors: 'Actores no encontrados',
    reason: 'Motivo',
    reasonPlaceHolder: 'Selecciona un Motivo',
    noReason: 'Motivo no encontrado',
    noReasons: 'Motivos no encontrados',
    example: 'Ejemplo',
    code: 'Código',
    codePlaceHolder: 'Código de la amenaza',
    createThreat: 'Crear Amenaza',
    editLoader: 'Editando amenaza...',
    createLoader: 'Creando amenaza...',
    deleteLoader: 'Eliminando amenaza...',
    getThreatsError: 'Ha ocurrido un error al obtener las amenazas: ',
    getThreatError: 'Ha ocurrido un error al consultar la amenaza: ',
    createThreatError: 'Ha ocurrido un error al crear la amenaza: ',
    editThreatError: 'Ha ocurrido un error al editar la amenaza: ',
    showhreatError: 'Ha ocurrido un error al consultar la amenaza: ',
    deleteThreatError: 'No ha sido posible eliminar la amenaza porque tiene activos o vulnerabilidades asociadas',
    createThreatSuccess: 'Amenaza creada exitosamente',
    editThreatSuccess: 'Amenaza actualizada exitosamente',
    deleteThreatSuccess: 'Amenaza eliminada exitosamente',

};
