export default {

    impacts: 'Impactos',
    impactFactors: 'Factores de Impacto',
    impactFactor: 'Factor de impacto',
    impactMeasures: 'Medidas de Impacto',
    saveMeasures: 'Guardar Medidas',
    addImpactFactor: 'Agregar Factor de Impacto',
    savingImpactMeasures: 'Guardando Medidas de Impacto',
    impactMeasuresSavedSuccessfully: 'Medidas de impacto guardadas correctamente',
    loadingImpactMeasures: 'Cargando medidas de impacto...',
    savingImpactFactor: 'Guardando factor de impacto',
    theImpactFactor: 'el factor de impacto!',
    errorDeletingImpactFactor: 'Ha ocurrido un error al eliminar el factor de impacto!',
    impactFactorDeletedSuccessfully: 'Factor de impacto eliminado correctamente!',
    impact: 'Impacto',
    impactLevel: 'Nivel de impacto',

};
