export default {

    name: 'Name',
    return: 'Back',
    preferredLanguage: 'Preferred Language',
    languagePlaceHolder: 'Select a Language',
    noLanguage: 'Language not found',
    noLanguages: 'Languages ​​not found',
    information: 'Information',
    description: 'Description',
    create: 'Create',
    module: 'Module',
    actions: 'Actions',
    activeText: 'Active',
    inactiveText: 'Inactive',
    details: 'Details',
    status: 'Status',
    greeting: 'Hello,',
    cancel: 'Cancel',
    edit: 'edit',
    address: 'Address',
    category: 'Category',
    categoryPlaceHolder: 'Select a category',
    noCategory: 'Category not found',
    noCategories: 'Categories not found',
    warning: 'Warning',
    success: 'Success!',
    unknown: 'Unknown',
    genericError: 'An error occurred while',
    update: 'update',
    enableEdit: 'Enable to edit',
    editWarning: 'You must enable editing if you want to modify this field',
    confirmationMessage: 'Are you sure you want to continue?',
    all: 'All',
    search: 'Search...',
    noOptions: 'There are no options',
    atention: '¡Atention!',
    level: 'Level',
    correctly: 'correctly',
    updated: 'updated',
    created: 'created',
    save: 'save',
    loadingLabel: 'Loading Information...',
    finalized: 'Finalized',
    code: 'Code',
    start: 'Start',
    end: 'End',
    oups: '¡Ups!',

};

