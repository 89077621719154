import { TRANSACTION_TYPES } from '../utils/constants';

const scopesRoutes = [
    {
        path: '/scopes',
        name: 'ScopesPage',
        component: () => import('../views/scopes/ScopesComponent.vue'),
    },
    {
        path: '/scopes/create',
        name: 'ScopeCreatePage',
        component: () => import('../views/scopes/ScopesManagerComponent.vue'),
        props: { type: TRANSACTION_TYPES.CREATE }
    },
    {
        path: '/scopes/edit/:scopeId',
        name: 'ScopesEditPage',
        component: () => import('../views/scopes/ScopesManagerComponent.vue'),
        props: route => ({ type: TRANSACTION_TYPES.EDIT, scopeId: route.params.scopeId })
    },
    {
        path: '/scopes/show/:scopeId',
        name: 'ScopesShowPage',
        component: () => import('../views/scopes/ScopesManagerComponent.vue'),
        props: route => ({ type: TRANSACTION_TYPES.SHOW, scopeId: route.params.scopeId })
    },
    {
        path: '/scopes/select',
        name: 'ScopesSelectPage',
        component: () => import('../views/auth/SelectScope.vue'),
    },
];

export default scopesRoutes;