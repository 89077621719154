const impactsRoutes = [
    {
        path: '/risks',
        name: 'RisksPage',
        component: () => import('../views/risk/RiskComponent.vue'),
        props: { type: 'C' },
        meta: { requiresAuth: true }
    },
    {
        path: '/risks/management',
        name: 'RisksManagementPage',
        component: () => import('../views/risk/RiskManagementComponent.vue'),
        props: { type: 'C' },
        meta: { requiresAuth: true }
    }
];

export default impactsRoutes;