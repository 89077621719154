export default {
    recoveryPassword: 'Recuperar contraseña',
    login: 'Iniciar sesión',
    loginWithGoogle: 'Iniciar sesión con Google',
    loginWithOutlook: 'Iniciar sesión con Outlook',
    notAccount: '¿No tienes cuenta?',
    notAccountRegister: 'Regístrate',
    loginError: 'Ha ocurrido un error al iniciar sesión',
    resetPasswordError: 'Ha ocurrido un error al reestablecer la contraseña:',
};
